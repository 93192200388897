
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isEndingDateBeforeStartingDate, isRequired } from '@/utils/validation'
import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetUsersBasic } from '@/api/user'
import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useCreateDemand, useUpdateDemand } from '@/api/demand'
import { useGetEnumItemsBasic } from '@/api/enumItem'

import { isOfType } from '@/utils/types/isOfType'
import { DemandOutput, DemandInput } from '@/api/types/demand'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'AddEditDemandDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    demandToEdit: {
      type: Object as PropType<DemandOutput>,
      default: null,
    },
    demandToDuplicate: {
      type: Object as PropType<DemandOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.demandToEdit))

    const { exec: getUsersBasic, data: users, isLoading: isLoadingUsersBasic } = useGetUsersBasic()

    if (hasSufficientRights(Rights.BASIC_READ)) {
      getUsersBasic()
    }
    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnitsBasic } = useGetProfUnitsBasic()
    getProfUnitsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()
    getOrgUnitsBasic()

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()
    getEnumItemsBasic({ params: { enumItemAssignment: 'DEMAND_TYPE' } })

    const FORM_FIELDS: FormField[] = [
      {
        value: 'demandDriver',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => users.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.demands.form.demandDriver') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'type',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        isRequired: false,
        rules: [],
        dropdownTextProp: 'name',
      },
      {
        value: 'professionalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnitsBasic.value),
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.demands.form.professionalUnit') as string)],
        dropdownTextProp: 'name',
      },
      {
        value: 'organizationalUnit',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('acquisition.demands.form.organizationalUnit') as string),
        ],
        dropdownTextProp: 'name',
      },
      {
        value: 'demandProfile',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.demands.form.demandProfile') as string)],
      },
      {
        value: 'payGroup',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: false,
        rules: [],
      },
      {
        value: 'count',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.demands.form.count') as string)],
      },
      {
        value: 'demandStart',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('acquisition.demands.form.demandStart') as string)],
      },
      {
        value: 'demandEnd',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('acquisition.demands.form.demandEnd') as string),
          (value: string) =>
            form.value.demandStart ? isEndingDateBeforeStartingDate(form.value.demandStart, value) : true,
        ],
      },
      {
        value: 'estimatedStart',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'demandClosed',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
      {
        value: 'dailyWorkingTime',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
      {
        value: 'hourlyRate',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: false,
        rules: [],
      },
    ]
    const demandToCreate = props.demandToDuplicate !== null ? props.demandToDuplicate : ({} as DemandInput)

    const form = ref<DemandInput | DemandOutput>(isEditMode.value ? cloneDeep(props.demandToEdit) : demandToCreate)

    const { createDemand, isLoading: isLoadingCreateDemand } = useCreateDemand()

    async function onAdd(): Promise<void> {
      if (!isOfType<DemandOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)
        try {
          await createDemand(updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateDemand, isLoading: isLoadingUpdateDemand } = useUpdateDemand()

    async function onEdit(): Promise<void> {
      if (isOfType<DemandOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<DemandOutput>(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

        updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

        try {
          await updateDemand(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditDemand = computed(() => isLoadingCreateDemand.value || isLoadingUpdateDemand.value)

    watch(
      () => {
        props.demandToEdit, props.demandToDuplicate
      },

      () => {
        if (props.demandToEdit) {
          form.value = props.demandToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
        if (props.demandToDuplicate) {
          form.value = props.demandToDuplicate

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },

      {
        immediate: true,
      }
    )

    function close(): void {
      emit('reload-demands')

      emit('close')
    }

    const highHourlyRate = ref(false)

    watch(
      () => form.value.hourlyRate,
      () => {
        return (highHourlyRate.value = Number(form.value.hourlyRate) >= 300 ? true : false)
      }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditDemand,

        highHourlyRate,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
          isEndingDateBeforeStartingDate,
        },
      },
    })
  },
})
